import React, {useEffect, useState} from 'react';
import Div from "../Div/Div";
import "./Header.scss";
import {BsFillTelephoneFill} from "react-icons/bs";
import {AiOutlineMail} from "react-icons/ai";
import LangSwitch from "../LangSwitch/LangSwitch";
import {GiHamburgerMenu} from "react-icons/gi";
import classNames from "classnames";
import {RxCross1} from "react-icons/rx";
import {Link} from "react-router-dom";
import {lang, lg} from "../../helpers/lang/lang";
import {useParams} from "react-router";
import {conn} from "../../helpers/request/connect";
import {langChoose} from "../../helpers";
const logo = require("../../images/logo.svg");
const Header = () => {

    const params = useParams();

    const [opened, setOpened] = useState(false);
    const [disciplines, setDisciplines] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(()=>{
        conn("web/getMenu").then((res:any)=> {
            if(res.response){
                setCategories(res.response.categories);
                setDisciplines(res.response.disciplines);
            }
        })
    }, []);

    useEffect(()=>{
        setOpened(false);
    }, [params])

    const menuClasses = classNames("header-menu-content", {"opened": opened})

    return (
        <Div id={"header"}>
            <Div id={"header-in"} alignH={"center"} className={"width"} gap={20}>
            <Link to={"/"}><Div className={"logo"}>
                <img src={logo.default}/>
            </Div></Link>
            <Div className={menuClasses} column alignV={"between"}>
                <Div className={"header-up-content"} alignV={"between"}>
                 <Div className={"header-info"} gap={8} alignH={"center"}>
                     <BsFillTelephoneFill/> {lang["phone"]} +374 (93) 112-123
                 </Div>
                 <Div className={"header-info"} gap={8} alignH={"center"}>
                     <BsFillTelephoneFill/> {lang["phone"]} +374 (93) 112-123
                 </Div>
                 <Div className={"header-info"} gap={8} alignH={"center"}>
                     <AiOutlineMail/> info@medstandard.am
                 </Div>
                    <input className={"search-input"}/>
                    <Div className={"menu-close"} onClick={()=>setOpened(false)}>
                        <RxCross1/>
                    </Div>
                </Div>
                <Div className={"header-down-content"} alignV={"end"} alignH={"center"} gap={6}>
                    <Link to={"/"}><Div className={"menu-item"}>{lang["home"]}</Div></Link>
                    {disciplines?.length ?<Div className={"menu-item"}>{lang["disciplines"]}
                        <Div className={"menu-sub-content"} wrap={true}>
                            {disciplines.map(dis=>{
                                return <Link to={"/" + lg + "/discipline/" + dis.id} key={"menu-item"+ dis.id}>
                                    <Div className={"menu-sub-item"}>{langChoose(dis, "title_", lg)}</Div>
                                </Link>
                            })}
                        </Div>
                    </Div> : null}
                    {categories?.length ?<Div className={"menu-item"}>{lang["products"]}
                        <Div className={"menu-sub-content"} wrap={true}>
                            {categories.map(dis=>{
                                return <Link to={"/" + lg + "/product-category/" + dis.id} key={"menu-item"+ dis.id}>
                                    <Div className={"menu-sub-item"}>{langChoose(dis, "title_", lg)}</Div>
                                </Link>
                            })}
                        </Div>
                    </Div> : null}
                    <Link to={"/" + lg + "/services"}><Div className={"menu-item"}>{lang["services"]}</Div></Link>
                    <Link to={"/" + lg + "/media-library"}><Div className={"menu-item"}>{lang["media-library"]}</Div></Link>
                    <Link to={"/" + lg + "/events"}> <Div className={"menu-item"}>{lang["events"]}</Div></Link>
                    <Link to={"/" + lg + "/blog"}><Div className={"menu-item"}>{lang["blog"]}</Div></Link>
                    <LangSwitch/>
                </Div>

            </Div>
             <Div className={"menu-button"} onClick={()=>setOpened(!opened)}>
                 <GiHamburgerMenu/>
             </Div>
            </Div>
        </Div>
    );
};

export default Header;
